<template>
  <div class="container-fluid my-3 target" style="min-height:50vh">
    <!-- <div class="my-3" style="text-align:left" v-if="target">
      <h6 v-if="target.standard">
        <span class="title">企業標準說明：</span>
        <br>
        <span v-html="target.standard.replace(/(\r\n)|(\n)/g,'<br>')"></span>
      </h6>
    </div> -->
    <div class="row" v-if="target">
      <div class="col-12">
        <div class="downloadBtn">
          <!-- <button @click="downloadCertificate" v-if="certificate" type="button" class="btn btn-outline-danger">證書下載</button>
          <button v-else type="button" class="btn btn-outline-danger" disabled>證書下載</button> -->
        </div>
      </div>
      <div class="col-md-3" v-if="target.classify.rule !== 0 && target">
        <div class="target-box my-3">
          <div class="target-title">
            {{ $t('Overview.classify') }}
          </div>
          <div class="target-content" >
            <p>
              {{ $t('Personal.Target.Goal') }}：完成學前測驗
            </p>
            <p>
              {{ $t('Personal.Target.Result') }}：{{target.classify.user === 0 ? '未完成' : '完成'}}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div v-if="target.classify.user === 0" class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div v-else class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 1000%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{target.classify.user === 0 ? '0%' : '100%'}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="target.time.rule !== 0 && target">
        <div class="target-box my-3" v-if="target.time">
          <div class="target-title">
            {{ $t('Overview.time') }}
          </div>
          <div class="target-content">
            <p>
              {{ $t('Personal.Target.Goal') }}：{{target.time.rule}}
            </p>
            <p>
              {{ $t('Personal.Target.Completed') }}：{{target.time.user}}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div class="progress">
                  <div class="progress-bar" role="progressbar" :style="{width: toPercent(convert(target.time.user)/convert(target.time.rule))}" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{toPercent(convert(target.time.user)/convert(target.time.rule))}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="target.course.rule !== 0 && target">
        <div class="target-box my-3" v-if="target.course">
          <div class="target-title">
            {{ $t('Overview.course') }}
          </div>
          <div class="target-content">
            <p>
              {{ $t('Personal.Target.Goal') }}：{{target.course.rule}} {{ $t('Personal.Target.Unit') }}
            </p>
            <p>
              {{ $t('Personal.Target.Completed') }}：{{target.course.user}} {{ $t('Personal.Target.Unit') }}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div class="progress">
                  <div class="progress-bar" role="progressbar" :style="{width: toPercent(target.course.user/target.course.rule)}" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{toPercent(target.course.user/target.course.rule)}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="target.final_test.rule && target">
        <div class="target-box my-3" v-if="target.final_test">
          <div class="target-title">
            {{ $t('Overview.final_test') }}
          </div>
          <div class="target-content">
            <p>
              {{ $t('Personal.Target.Goal') }}：完成學後測驗
            </p>
            <p>
              {{ $t('Personal.Target.Result') }}：{{target.final_test.user===0 ? '未完成' : '完成'}}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div v-if="target.final_test.user === 0" class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div v-else class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 1000%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{target.final_test.user === 0 ? '0%' : '100%'}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="target.pass_n1.rule && target">
        <div class="target-box my-3" v-if="target.pass_n1">
          <div class="target-title">
            {{ $t('Overview.pass_n1') }}
          </div>
          <div class="target-content">
            <p>
              {{ $t('Personal.Target.Goal') }}：通過
            </p>
            <p>
              {{ $t('Personal.Target.Result') }}：{{target.pass_n1.is_pass ? '通過' : '未通過'}}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div v-if="target.pass_n1.is_pass" class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 1000%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div v-else class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{target.pass_n1.is_pass ? '100%' : '0%'}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="target.pass_n2.rule && target">
        <div class="target-box my-3" v-if="target.course">
          <div class="target-title">
            {{ $t('Overview.pass_n2') }}
          </div>
          <div class="target-content">
            <p>
              {{ $t('Personal.Target.Goal') }}：通過
            </p>
            <p>
              {{ $t('Personal.Target.Result') }}：{{target.pass_n2.is_pass ? '通過' : '未通過'}}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div v-if="target.pass_n2.is_pass" class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 1000%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div v-else class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{target.pass_n2.is_pass ? '0%' : '100%'}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="target.pass_n3.rule && target">
        <div class="target-box my-3" v-if="target.course">
          <div class="target-title">
            {{ $t('Overview.pass_n3') }}
          </div>
          <div class="target-content">
            <p>
              {{ $t('Personal.Target.Goal') }}：通過
            </p>
            <p>
              {{ $t('Personal.Target.Result') }}：{{target.pass_n3.is_pass ? '通過' : '未通過'}}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div v-if="target.pass_n3.is_pass" class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 1000%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div v-else class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{target.pass_n3.is_pass ? '0%' : '100%'}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="target.pass_n4.rule && target">
        <div class="target-box my-3" v-if="target.course">
          <div class="target-title">
            {{ $t('Overview.pass_n4') }}
          </div>
          <div class="target-content">
            <p>
              {{ $t('Personal.Target.Goal') }}：通過
            </p>
            <p>
              {{ $t('Personal.Target.Result') }}：{{target.pass_n4.is_pass ? '通過' : '未通過'}}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div v-if="target.pass_n4.is_pass" class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 1000%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div v-else class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{target.pass_n4.is_pass ? '100%' : '0%'}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="target.pass_n5.rule && target">
        <div class="target-box my-3" v-if="target.course">
          <div class="target-title">
            {{ $t('Overview.pass_n5') }}
          </div>
          <div class="target-content">
            <p>
              {{ $t('Personal.Target.Goal') }}：通過
            </p>
            <p>
              {{ $t('Personal.Target.Result') }}：{{target.pass_n5.is_pass ? '通過' : '未通過'}}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div v-if="target.pass_n5.is_pass" class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 1000%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div v-else class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{target.pass_n5.is_pass ? '100%' : '0%'}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
// @ is an alias to /src
// import Video from '@/components/Video.vue'
import api from '../store/api'
export default {
  name: 'Target',
  data () {
    return {
      certificate: false
    }
  },
  props: {
    target: {
      require: false,
      default: null
    }
  },
  mounted () {
    this.getCertificate()
  },
  methods: {
    toPercent (num) {
      // console.log(Number(num*100).toFixed(0) )
      if ( Number(num*100).toFixed(0) >= 100) {
        return '100%'
      } else {
        if (num>0) {
          return Number(num*100).toFixed(0) + '%'
        } else {
            return '0%'
        }
      }
    },
    async getCertificate () {
      const { data } = await api.get(`/certificate`)
      this.certificate = data.data.certificate

    },
    async downloadCertificate () {
      return api.post('/certificate', {} , {responseType: 'blob'}).then ((response) => {
        if (response) {
          let fileName = 'Certificate'
          const url = URL.createObjectURL(new Blob([response.data],{
            type: 'image/png'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        } else {
          return Promise.reject()
        }
      })
    },
    convert (time) {
      return time.split(':').reduce((acc,time) => (60 * acc) + + time)
    }
  },
  components: {
    // Video
  }
}
</script>
<style lang="scss" scoped>
  .title {
    font-size: 1rem;
    font-weight: bold;
  }
  .downloadBtn {
    float: right;
  }
  .downloadIcon:hover {
    color: rgb(129, 129, 129);
  }
  .target-box {
    height: 180px;
    overflow: hidden;
    background-color: #ededed;
    border: .5px solid rgb(202, 202, 202);
    border-radius: 5px;
    .target-title {
      padding: 8px;
      background-color: #0041b9;
      color: #fff;
    }
    .target-content {
      text-align: left;
      padding: 20px;
      height: 80%;
    }
    .progress {
      margin: 5px;
      height: 10px;
      background-color: #fff;
      .progress-bar {
        background-color: red;
      }
    }
  }
</style>
