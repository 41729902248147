<template>
  <div class="login-bg">
    <transition name="fade" v-if="toggle">
      <div class="pop-up fixed-top" name="fade">
        <div class="login-box pop-box">
          <img style="width:250px" src="../assets/image/pic/logo_liveLearning.png" alt="">
          <font-awesome-icon class="pointer" @click="close" size="2x" :icon="['fa', 'times']"  style="color:rgba(255, 0, 0, .6);float: right;"/>
          <div class="mt-5">
            <form class="mt-4 px-4" v-if="!forget">
              <div class="group">
                <input class="input-text" v-on:keyup.enter="login" v-model="auth.account" type="text" required>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label class="input-text-label">{{ $t('Login.please_enter_your_email') }}</label>
              </div>
              <div class="group">
                <input class="input-text" v-on:keyup.enter="login" v-model="auth.password" type="password" required>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label class="input-text-label">{{ $t('Login.please_enter_password') }}</label>
              </div>
              <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="checkbox1" v-model="remember">
                  <label class="form-check-label" for="checkbox1">
                    記住我
                  </label>
                </div>
              <button type="button" style="width:100%" class="btn btn-danger" @click="login">{{ $t('Login.login') }}</button>
              <div class="mt-2" style="text-align: right;">
                <span class="underLine pointer" @click="openForgetPage">
                  {{ $t('Login.forgot_Password') }}
                </span>
              </div>
            </form>
            <form class="mt-4 px-4" v-else>
              <div class="group">
                <input class="input-text" v-model="auth.account" type="text" required>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label class="input-text-label">{{ $t('Login.please_enter_your_email') }}</label>
              </div>
              <button type="button" style="width:100%" class="btn btn-danger" @click="getEmail">{{ $t('Login.send_verification_email') }}</button>
            </form>
          </div>
        </div>
      </div>
    </transition>
    <nav class="navbar fixed-top  navbar-light bg-light" transparent>
      <img class="pointer" style="width:200px" src="../assets/image/pic/logo_liveLearning.png" alt="">
      <div class="mt-2 m-sm-0">
        <!-- <a @click="apply()" target="_blank" class="btn btn-danger mx-2">{{ $t('Login.register') }}</a> -->
        <!-- <a href="https://docs.google.com/forms/d/e/1FAIpQLSf0V2fjaQsRDSdvQetn8lunpCx9H5puxaUPE8woUHpw3IdhXw/viewform" target="_blank" class="btn btn-danger mx-2">{{ $t('Login.trial') }}</a> -->
        <button type="button" @click="openLogin()" class="btn btn-link">{{ $t('Login.login') }}</button>
      </div>
    </nav>
    <div class="banner">
      <div class="container">
        <div class="row">
          <div class="col-md-12 align-self-center">
            <h3 class="sub-title">生活、職場、旅遊、文化</h3>
            <h1 class="title">學好日語就從這裡開始</h1>
            <button type="button" @click="openLogin()" class="btn btn-danger mt-4">
              {{ $t('Login.start_my_course') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="architecture-banner py-5">
      <div class="container">
        <div class="row">
          <div class="mx-auto">
            <h2 class="title">全新學習架構</h2>
            <img style="width:100%" src="../assets/image/home/line_O.png" alt="">
          </div>
          <!-- <img style="width:92%; margin-left:3%" src="../assets/image/home/banner02_title.svg" alt=""> -->
        </div>
        <div class="row">
          <div class="col-md-4">
            <img style="width:100%;" src="../assets/image/home/banner02_info_S_1.svg" alt="">
          </div>
          <div class="col-md-4">
            <img style="width:100%;" src="../assets/image/home/banner02_info_S_2.svg" alt="">
          </div>
          <div class="col-md-4">
            <img style="width:100%;" src="../assets/image/home/banner02_info_S_3.svg" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="topic-banner py-5">
      <div class="container">
        <div class="row">
          <div class="mx-auto">
            <h2 class="title">多元學習主題</h2>
            <img style="width:100%" src="../assets/image/home/line_P.png" alt="">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <img style="width:100%" src="../assets/image/home/banner03_info_base.png" alt="">
          </div>
          <div class="col-md-6">
            <img style="width:100%" src="../assets/image/home/banner03_info_life.png" alt="">
          </div>
          <div class="col-md-6">
            <img style="width:100%" src="../assets/image/home/banner03_info_business.png" alt="">
          </div>
          <div class="col-md-6">
            <img style="width:100%" src="../assets/image/home/banner03_info_culture.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="process-banner">
      <div class="container">
        <div class="row">
          <div class="mx-auto">
            <h2 class="title mt-5">完善學習流程</h2>
            <img style="width:100%" src="../assets/image/home/line_P.png" alt="">
          </div>
        </div>
        <div class="row rwd-hide my-4">
          <img style="width:90%; margin-left:5%;margin-top:50px" src="../assets/image/home/banner05_info1.svg" alt="">
          <img style="width:90%; margin-left:5%;margin-top:100px" src="../assets/image/home/banner05_info2.svg" alt="">
          <img style="width:90%; margin-left:5%;margin-top:100px" src="../assets/image/home/banner05_info3.svg" alt="">
        </div>
        <div class="row my-4 rwd-banner">
          <img class="col-12" src="../assets/image/home/banner05_info_S_1.png" alt="">
          <img class="col-12" src="../assets/image/home/banner05_info_S_2.png" alt="">
          <img class="col-12" src="../assets/image/home/banner05_info_S_3.png" alt="">
        </div>
      </div>
    </div>
    <div class="p-4 feed-back">
      <carousel class="my-4" style="width:180vw; margin-left:-40vw" :navigationEnabled="false" :perPage="3" :paginationEnabled="false" navigation-next-label ="❯" navigation-prev-label ="❮">
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user01.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h5 class="card-title">日商企畫專員</h5>
                  <h5 class="card-title">阮小姐</h5>
                  <p class="card-text">終於有一個網站可以讓我用下班後零碎的時間精進日文了!課程編排循序漸進，而且內容豐富，我很滿意。</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user02.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h6 class="card-title">高中英文老師</h6>
                  <h3 class="card-title">李小姐</h3>
                  <p class="card-text">一直都很想加強自己的第二外語，念書時也對日語很有興趣。後來發現live日本語網站很適合像我這樣的自學者。現在每天都會登入上幾課課程。</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user03.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h5 class="card-title">半導體業PM</h5>
                  <h5 class="card-title">曾先生</h5>
                  <p class="card-text">在我的工作領域，如果會日語是一大加分。因此過去我曾經花大錢去日文補習班，可惜成效不彰。利用live日本語網站學日文之後，發現課程的設計讓我感覺輕鬆無負擔，學習起來也很有成就感。</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user04.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h5 class="card-title">人力顧問公司專員</h5>
                  <h5 class="card-title">林小姐</h5>
                  <p class="card-text">我通常會利用通勤時間觀看互動課程，之後再利用每課單字、文法加深自己的觀念，之後我打算挑戰看看日檢考試!</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user05.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h5 class="card-title">公務員</h5>
                  <h5 class="card-title">洪先生</h5>
                  <p class="card-text">課程內容從生活、旅遊到職場都有，很多元。加上首頁的每日一句、推薦課程，讓我常有學習的驚喜，讚!</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
      <carousel  style="width:200vw; margin-left:-50vw" class="my-4" :navigationEnabled="false" :perPage="2" :paginationEnabled="false" navigation-next-label ="❯" navigation-prev-label ="❮">
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user01.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h5 class="card-title">理財專員</h5>
                  <h5 class="card-title">蔡先生</h5>
                  <p class="card-text">我本來就有一點日文的基礎，開始使用live日本語網站之後，發現課程內容不僅教日文，還能認識豐富的日本文化、風俗民情，讓我覺得獲益良多。</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user02.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h5 class="card-title">軟體開發工程師</h5>
                  <h5 class="card-title">劉先生</h5>
                  <p class="card-text">我從小就很喜歡日本的動漫、流行，所以很早就有自學日文的經驗。我覺得透過live日本語網站，更能夠有系統地學習日文，原來不用買課本、不用看電視，也能輕鬆學好日文</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import Video from '@/components/Video.vue'
import api from '../store/api'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Login',
  data () {
    return {
      forget: false,
      toggle: false,
      auth: {
        account: '',
        password: ''
      },
      remember: window.localStorage.getItem('account') ? true : false
    }
  },
  props: {
    assistData: {
    }
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'authorized',
      'authInfo'
    ])
  },
  mounted () {
    if (this.remember) {
      this.auth.account = window.localStorage.getItem('account')
    }
    if (this.authorized) {
      this.$router.push({ path: 'home' })
    }
  },
  methods: {
    ...mapActions({
      dologin: 'login',
      getUser: 'getUser'
    }),
    nextSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage());
    },
    prevSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage());
    },
    openLogin () {
      this.toggle = true
      this.forget = false
    },
    close () {
      this.toggle = false
      this.forget = false
    },
    openForgetPage () {
      this.forget = true
      this.auth.account = ''
    },
    async login () {
      const vm = this
      try {
        await this.dologin({ email: this.auth.account, password: this.auth.password })
        await this.getUser ()
        if (this.remember) {
          window.localStorage.setItem('account', vm.auth.account)
        } else {
          window.localStorage.removeItem('account')
        }
        this.$router.push({ name: 'Home' })
      } catch (error) {
        if (error) {
          this.setTimeout(
            this.$swal({
            title: '登入失敗',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: '確定'
          })
          , 1000)
        }
      }
    },
    async apply () {
      this.$swal({
        title: '輸入報名代碼',
        input: 'text',
        inputPlaceholder: '輸入報名代碼',
        inputAttributes: {
          maxlength: 20,
          autocapitalize: 'off',
          autocorrect: 'off'
        }
      }).then(value => {
        if (value.value){
          window.open(`https://store.liveabc.com/ent/registration/livelearning/${value.value}/signup`, "_blank")
        }
      })
    },
    async getEmail () {
      try {
        const {data} = await api.post(`password/email?email=${this.auth.account}`)
        this.$swal({
          title: data.email || data.status,
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
      } catch (error) {
        this.$swal({
          title: error.response.data.errors.email[0],
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
      }
    }
  },
  components: {
    // Video
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/custom.scss";
@import "../assets/styles/color.scss";
  .login-bg {
    position: relative;
  }
  .group {
    position:relative;
    margin-bottom: 35px;
  }
  .input-text {
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;
  }
  .input-text:focus	{ outline: none; }

  /* LABEL ======================================= */
  .input-text-label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  /* active state */
  .input-text:focus ~ label, input:valid ~ label {
    top: -20px;
    font-size: 14px;
    color: #000000;
  }
  .arrow {
    width: 50px;
  }
  /* BOTTOM BARS ================================= */
  .bar {
    position:relative;
    display:block;
    width:100%;
  }
  .bar:before, .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #000000;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  .bar:before {
    left: 50%;
  }
  .bar:after {
    right: 50%;
  }

  /* active state */
  input:focus ~ .bar:before, input:focus ~ .bar:after {
    width: 50%;
  }
.underLine {
  text-decoration: underline;
}
  .title {
    font-weight: bolder;
  }
  .banner {
    height: 65vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-image: url('../assets/image/home/banner01_bg_W.png');
    .container {
      height: 70vh;
      .row {
        margin-top: 30px;
        color: $main-color;
        text-align: center;
        height: 100%;
        h1 {
          font-size: 4rem;
        }
      }
    }
  }
  .topic-banner {
    background-size: cover;
    background-image: url('../assets/image/home/banner03_bg.jpg');
  }
  .architecture-banner {
    background-size: cover;
    background-image: url('../assets/image/home/banner02_bg.jpg');
  }
  .feature-banner {
    // height: 50vh;
    background-size: contain;
    background-image: url('../assets/image/home/banner02_bg.jpg');
    .card {
      min-height: 350px;
      box-shadow: 3px 3px 5px rgb(141, 141, 141);
      .card-img-top {
        border: #fff 12px solid;
      }
      .card-header {
        background-color: #DB523F;
        font-weight: bold;
        font-size: 1.5rem;
        color: #fff;
      }
    }
    .info-box {
      white-space:nowrap;
      font-weight: bolder;
      .info-title {
        color: red;
      }
      div	{
        padding: 10px;
        background-color: #fff;
        margin: 5px;
      }
    }
  }
  .process-banner {
    // height: 80vh;
    background-size: cover;
    background-image: url('../assets/image/home/banner05_bg.jpg');
  }
  .apply-box {
    background-color: #E86B5A;
    color: #fff;
    h3 {
      font-weight: bolder;
      font-size: 2rem;
    }
  }
  .rwd-banner {
    display: none;
  }
  .feed-back {
    overflow:hidden;
    background-color: $light-color;
  }
  .info-bg {
    padding: 20px;
    background-color: #EC62AC;
  }
  .pop-up {
    text-align: left;
    width: 100%;
    height: 103vh;
    background-color: #000000ad !important;
    z-index: 1040;
    .login-box {
      @include center();
      padding: 40px;
      border-radius: 20px;
      box-shadow: 10px;
      background-color: #fff;
      width: 580px;
      max-height: 580px;
      box-shadow: 10px 12px 12px 12px rgba(0, 0, 0, 0.4);
      z-index: 1000;
      margin: auto;
    }
  }
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media screen and (max-width: 601px) {
  .login-box {
    width: 380px !important;
  }
  .rwd-banner {
    display: block !important;
  }
  .banner {
    background-position: center;
    .title {
      font-size: 2.5rem !important;
    }
    .sub-title {
      font-size: 1.25rem !important;
    }
  }
  .pop-up {
    width: 100%;
  }
}
</style>
