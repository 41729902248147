<template>
  <div>
    <div class="banner">
      <div class="banner-box">
        <div>
          <h2>
            寫作教室
          </h2>
          <p>
            Essay Revision
          </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row my-3">
        <div class="col-12">
          <div>
            <p>學英文這麼久，想更紮實提升英文寫作能力嗎？其實，要寫出一篇好的文章並非難事，只要持之以恆練習，就能讓文字表達更加流暢。</p>
            <p>為了提升您的英文寫作實力，精心規劃寫作批改練習專區，共分為兩部份：第一部份是「前導練習」，可作為正式寫作前準備；第二部份是「文章寫作」，請嘗試完成一篇您的作品，並提交由老師批改。相關批改範例 <a download="參考範例" href="/files/example.docx">請點我參考</a>，快動手寫出你的第一篇文章吧！</p>
            <div>
              <ul>
                <li>建議先選擇難度★的主題，透過基礎字彙及段落架構，鍛鍊寫作基本功。</li>
                <li>接著再嘗試難度★★的主題，逐步穩固整體寫作結構。</li>
                <li>最後挑戰難度★★★的進階主題，打造漂亮英語寫作力。</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="d-flex my-3">
              <h3 class="title pr-3">生活實用  <span class="badge badge-danger">A2</span>  <span class="badge badge-danger">B1</span></h3>
              <div class="line"></div>
            </div>
            <table class="rwd-table"  v-if="lifeData.length>0">
              <tr>
                <th style="width:20%">寫作難易程度</th>
                <th style="width:50%">文章主題</th>
                <th style="width:20%">狀態</th>
                <th style="width:10%">前往</th>
              </tr>
              <tr v-for="(item, index) in lifeData" :key="index">
                <td data-th="寫作難易程度">
                  <span v-for="(item, index) in item.ranking" :key="index"><img style="width:25px" src="../../assets/image/icon&btn/icon_other/icon_star_on.png" alt=""></span>
                  <span v-for="(item, index) in (3-item.ranking)" :key="index"><img style="width:25px" src="../../assets/image/icon&btn/icon_other/icon_star_off.png" alt=""></span>
                </td>
                <td data-th="文章主題">{{item.title}} {{item.title_en}}</td>
                <td data-th="狀態">
                  {{ item.user_date?moment(item.user_date).format('YYYY/MM/DD'):'' }}
                  <span  :class="{'approved': item.user_status ==='approved', 'denied': item.user_status === 'denied'}" >{{amendStatus(item.user_status)}}</span>
                  <a v-if="item.user_file !== null" download :href="item.user_file">
                    <img class="mx-2" src="../../assets/image/icon&btn/icon_have_btn_function/btn_download.png" alt="">
                  </a>
                </td>
                <td data-th="前往"><button type="button" class="btn btn-danger"  @click="goWriting(item)">Go</button></td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-12">
          <div class="row ">
            <div class="d-flex my-3">
              <h3 class="title pr-3">職場商用  <span class="badge badge-danger">B1</span>  <span class="badge badge-danger">B2</span></h3>
              <div class="line"></div>
            </div>
            <table class="rwd-table" v-if="bizData.length>0">
              <tr>
                <th style="width:20%">寫作難易程度</th>
                <th style="width:50%">文章主題</th>
                <th style="width:20%">狀態</th>
                <th style="width:10%">前往</th>
              </tr>
              <tr v-for="(item, index) in bizData" :key="index">
                <td data-th="寫作難易程度">
                  <span v-for="(item, index) in item.ranking" :key="index"><img style="width:25px" src="../../assets/image/icon&btn/icon_other/icon_star_on.png" alt=""></span>
                  <span v-for="(item, index) in (3-item.ranking)" :key="index"><img style="width:25px" src="../../assets/image/icon&btn/icon_other/icon_star_off.png" alt=""></span>
                </td>
                <td data-th="文章主題">{{item.title}} {{item.title_en}}</td>
                <td data-th="狀態">
                  {{ item.user_date?moment(item.user_date).format('YYYY/MM/DD'):'' }}
                  <span :class="{'approved': item.user_status ==='approved', 'denied': item.user_status === 'denied'}" >{{amendStatus(item.user_status)}}</span>
                  <a v-if="item.user_file !== null" download :href="item.user_file">
                    <img class="mx-2" src="../../assets/image/icon&btn/icon_have_btn_function/btn_download.png" alt="">
                  </a>
                </td>
                <td data-th="前往"><button type="button" class="btn btn-danger" @click="goWriting(item)">Go</button></td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-12">
          <div class="row ">
            <div class="d-flex my-3">
              <h3 class="title pr-3">寫作批改說明</h3>
              <div class="line"></div>
            </div>
          </div>
          <div class="row">
            <div>
              <p>
                1. 寫作字數：每篇文章字數限180字（不得低於120字）。
              </p>
              <p>
                2. 批改進度：系統將於寫作日隔天起算約七個工作天派送電子郵件通知文章批改結果（請注意：七個工作天，不包含星期六日和國定例假日） 。​
              </p>
              <p>
                3. 批改規範：寫作批改之文章內容，以學員練習寫作為主，請勿將公司相關文件、法務合約等文稿上傳。​
              </p>
              <p>
                4. 狀態查詢：課程期間同一主題文章限送出批改一次，提交後將由外師排程批改，透過上表狀態欄位亦可查詢最新批改進度。​
              </p>
              <p>
                5. 批改要點：外師將依照全篇文章之段落結構、語法通順、字彙使用等進行批改，並提供整體文章總結評語。​
              </p>
              <p>
                6. 退件說明：若文章被退件，可能原因如下：​
              </p>
              <ul>
                <li>文章內容尚未完成​</li>
                <li>文章內容偏離主題​</li>
                <li>文章內容超出批閱範圍（例：研究報告、學術論文、公司合約……等）​</li>
                <li>文章內容涉及商業或廣告用途​</li>
                <li>文章內容含有違反善良風俗或其他不法文字​</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from '@/components/Pagination.vue'
export default {
  name: 'WritingList',
  components: {
    Pagination
  },
  computed: {

  },
  data () {
    return {
    }
  },
  props:{
    lifeData: {
      require: false
    },
    bizData: {
      require: false
    },
  },
  mounted () {
    this.scrollTop()
  },
  methods: {
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    },
    amendStatus (el) {
      switch (el) {
        case 'waiting':
          return '尚未提交'
        case 'approved':
          return '已完成'
        case 'uploaded':
          return '批改中'
        case 'denied':
          return '退件'
      }

    },
    goWriting (el) {
      const self = this
      self.$router.push({ name: 'WritingEditor', params: { id: el.id } })
      // self.$swal({
      //   title: '文章內容使用同意條款',
      //   text: '歡迎使用「LiveLearning PRO寫作教室」功能，在您上傳此篇文章之前，請詳細閱讀文章內容使用同意條款的內容。「LiveLearning PRO寫作教室」是由希伯崙股份有限公司（以下簡稱「本公司」）所提供，當您點選〔我同意〕按鈕並上傳此篇文章時，即視為您已閱讀文章內容使用同意條款並同意遵守所有內容。若您不同意文章內容使用同意條款內容，請選擇〔不同意〕按鈕上傳此篇文章以結束程序。對於您所撰寫、並經本公司修改後之文章，本公司得不限地域、時間與次數，以任何形式轉載、重製、改作、散布、編輯、出版、發行、公開口述、公開播送、公開傳輸及公開展示，並得將前開修改後之文章公布置於本網站上，供其他使用客戶瀏覽及參考，您不對本公司主張著作權受侵害。',
      //   showCancelButton: true,
      //   confirmButtonText: '同意',
      //   cancelButtonText: '不同意',
      //   closeOnConfirm: false
      // }).then(function (isConfirm) {
      //   if (isConfirm.value === true) {
      //     self.$router.push({ name: 'WritingEditor', params: { id: el.id } })
      //   }
      // })
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../assets/styles/color.scss";
  @import "../../assets/styles/custom.scss";
  .container {
    text-align: left;
  }
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    // width: 100%;
    width: 98%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
  .banner {
    height: 250px;
    background-image: url('../../assets/image/pic/banner_bg_write_top.jpg');
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-position: center;
    background-size: cover;
    .banner-box {
      padding: 60px 0 10px 0;
      h2 {
        font-size: 3rem;
        font-weight: 800;
        margin-bottom: 1rem;
      }
      p {
        color: rgb(47, 161, 255);
        font-weight: 600;
        margin: 0;
        font-size: 1.25rem;
      }
    }
  }
  table {
    text-align: center;
    border-collapse: collapse;
    width: 100%;
    overflow: auto;
  }
  th {
    overflow:hidden;
    background: #428bca;
    border: 4px solid #fff;
    color: #fff;
    text-align: center !important;
    padding: 8px;
  }
  td {
    background-color: rgb(230, 230, 230);
    border: 4px solid #fff;
    text-align: center !important;
    padding: 8px;
  }
  .rwd-table {
    margin: auto;
    min-width: 300px;
    max-width: 100%;
    border-collapse: collapse;
  }

  .rwd-table tr:first-child {
    border-top: none;
    background: #428bca;
    color: #fff;
  }

  .rwd-table tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #f5f9fc;
  }

  .rwd-table tr:nth-child(odd):not(:first-child) {
    background-color: #ebf3f9;
  }

  .rwd-table th {
    display: none;
  }

  .rwd-table td {
    display: block;
  }

  .rwd-table td:first-child {
    margin-top: .5em;
  }

  .rwd-table td:last-child {
    margin-bottom: .5em;
  }

.rwd-table td:before {
  content: attr(data-th) "： ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: #000;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  padding: .5em 1em;
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
  .rwd-td {
    display: block !important;
    background-color: #04a2d6;
  }
  .rwd-table th,
  .rwd-table td {
    text-align: left  !important;
  }
}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
  .approved {
    color: green;
  }
  .denied {
    color: red;
  }
}
</style>
