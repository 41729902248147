<template>
  <div class="container-fluid my-3" style="min-height:50vh">
    <div class="row">
      <div class="col-12">
        <div class="d-flex title-box">
          <div class="title" :class="{'active': currentPage==='classify'}"  @click="doSubTitle('classify')">{{$t('Personal.Exam.Placement_test')}}</div>
          <div class="title" v-if="examRole" :class="{'active': currentPage==='regular'}" @click="doSubTitle('regular')">{{$t('Personal.Exam.Challenge_test')}}</div>
          <div class="title" v-if="examRole" :class="{'active' : currentPage==='simulation'}"  @click="doSubTitle('simulation')">{{$t('Personal.Exam.More_practice')}}</div>
          <div class="title" v-if="examRole" :class="{'active' : currentPage==='verify'}"  @click="doSubTitle('verify')">學後測驗</div>
        </div>
      </div>
    </div>
    <div class="alert alert-warning" role="alert">
      為提供最佳應考體驗，請以電腦完成本區測驗
    </div>
    <div v-if="currentPage === 'classify'">
      <div style="text-align:left">
        <h6>
          在導入課程前，請先進行學前測驗，以了解目前對應日本語能力試驗(JLPT)之日語程度，以下說明：
        </h6>
        <ul class="pl-3">
          <li>每位學員限定只能測驗一次，請以電腦考試。</li>
          <li>測驗題型：模擬最新JLPT題型，考完立即呈現成績結果。</li>
          <li>測驗題數：共40小題，滿分180分。</li>
          <li>測驗時間：約 60 分鐘，畫面右上角有倒數計時，提醒作答剩餘時間。</li>
        </ul>
      </div>
      <table class="rwd-table">
        <tr>
          <!-- <th>{{$t('Personal.Exam.Type')}}</th> -->
          <th>{{$t('Personal.Exam.Result')}}</th>
          <th>{{$t('Personal.Exam.Exam_date')}}</th>
          <th>{{$t('Personal.Exam.Test_time')}}</th>
          <th>{{$t('Personal.Exam.Listening_score')}}</th>
          <th>{{$t('Personal.Exam.Reading_score')}}</th>
          <th>{{$t('Personal.Exam.Total_score')}}</th>
          <th class="rwd-hide">{{$t('Personal.Exam.State')}}</th>
        </tr>
        <tr v-for="(item, index) in exams" :key="index">
          <!-- <td class="rwd-td" :data-th="$t('Personal.Exam.Type')"> {{item.name}}</td> -->
          <td :data-th="$t('Personal.Exam.Result')"> {{item.level}}</td>
          <td :data-th="$t('Personal.Exam.Exam_date')"> {{item.finished_at?item.finished_at:'N/A'}}</td>
          <td :data-th="$t('Personal.Exam.Test_time')"> {{item.total_time}}</td>
          <td :data-th="$t('Personal.Exam.Listening_score')"> {{item.listening_score !== null ? item.listening_score : '-'}}</td>
          <td :data-th="$t('Personal.Exam.Reading_score')"> {{item.listening_score !== null ? item.reading_score : '-'}}</td>
          <td :data-th="$t('Personal.Exam.Total_score')"> {{item.listening_score !== null ? item.score : '-'}}</td>
          <td class="rwd-hide" :data-th="$t('Personal.Exam.Start')">
            <button v-if="!item.finished_at" type="button" @click="goExam(item)" class="btn btn-danger">開始</button>
            <button v-else :disabled="item.finished_at" type="button" class="btn btn-danger">完成</button>
          </td>
        </tr>
      </table>
      <div class="explanation" v-for="(item, index) in chats" :key="index" style="text-align:left">
        <div v-if="level == item.level">
          <h4>您的學前測驗分級結果是 <span class="red-text"> {{level}}</span>，以下是您的日語能力與學習建議說明:</h4>
          <div class="mt-3">
            <h5 class="red-text">日文能力</h5>
            <ul>
              <li v-for="(txt, idx) in item.text" :key="idx">{{txt}}</li>
            </ul>
          </div>
          <div class="mt-3">
            <h5 class="red-text">學習建議</h5>
            <p>{{item.advice}}​</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentPage === 'verify'">
      <div style="text-align:left">
        <h6>
          為挑戰自我實力，提供日本語能力試驗(JLPT)模擬測驗，以提升應考能力，以下說明：
        </h6>
        <ul class="pl-3">
          <li>每位學員限定只能測驗一次，請以電腦考試。</li>
          <li>測驗題型：模擬最新JLPT題型，考完立即呈現成績結果。</li>
          <li>測驗題數：共40小題，滿分180分。</li>
          <li>測驗時間：約 60 分鐘，畫面右上角有倒數計時，提醒作答剩餘時間。</li>
          <li>測驗期間：當期課程結束前3個月開放測驗</li>
        </ul>
      </div>
      <table class="rwd-table">
        <tr>
          <!-- <th>{{$t('Personal.Exam.Type')}}</th> -->
          <th>{{$t('Personal.Exam.Result')}}</th>
          <th>{{$t('Personal.Exam.Exam_date')}}</th>
          <th>{{$t('Personal.Exam.Test_time')}}</th>
          <th>{{$t('Personal.Exam.Listening_score')}}</th>
          <th>{{$t('Personal.Exam.Reading_score')}}</th>
          <th>{{$t('Personal.Exam.Total_score')}}</th>
          <th class="rwd-hide">{{$t('Personal.Exam.State')}}</th>
        </tr>
        <tr v-for="(item, index) in exams" :key="index">
          <!-- <td class="rwd-td" :data-th="$t('Personal.Exam.Type')"> {{item.name}}</td> -->
          <td :data-th="$t('Personal.Exam.Result')"> {{item.level?item.level:'-'}}</td>
          <td :data-th="$t('Personal.Exam.Exam_date')"> {{item.finished_at?item.finished_at:'N/A'}}</td>
          <td :data-th="$t('Personal.Exam.Test_time')"> {{item.total_time}}</td>
          <td :data-th="$t('Personal.Exam.Listening_score')"> {{item.listening_score !== null ? item.listening_score : '-'}}</td>
          <td :data-th="$t('Personal.Exam.Reading_score')"> {{item.listening_score !== null ? item.reading_score : '-'}}</td>
          <td :data-th="$t('Personal.Exam.Total_score')"> {{item.listening_score !== null ? item.score : '-'}}</td>
          <td v-if="item.view_id=== ''" class="rwd-hide" :data-th="$t('Personal.Exam.Start')">
            {{moment(item.begin_at).format('YYYY-MM-DD')}} 開放
          </td>
          <td v-else class="rwd-hide" :data-th="$t('Personal.Exam.Start')">
            <button v-if="!item.finished_at" type="button" @click="goExam(item)" class="btn btn-danger">開始</button>
            <button v-else :disabled="item.finished_at" type="button" class="btn btn-danger">完成</button>
          </td>
        </tr>
      </table>
      <div class="explanation" v-for="(item, index) in chats" :key="index" style="text-align:left">
        <div v-if="level == item.level">
          <h4>您的學後測驗分級結果是 <span class="red-text"> {{level}}</span>，以下是您的日語能力與學習建議說明:</h4>
          <div class="mt-3">
            <h5 class="red-text">日文能力</h5>
            <ul>
              <li v-for="(txt, idx) in item.text" :key="idx">{{txt}}</li>
            </ul>
          </div>
          <div class="mt-3">
            <h5 class="red-text">學習建議</h5>
            <p>{{item.advice}}​</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentPage === 'regular'">
      <div style="text-align:left">
        <h6>
          為挑戰自我實力，提供日本語能力試驗(JLPT)模擬測驗，以提升應考能力，以下說明：
        </h6>
        <ul class="pl-3">
          <li>請選取想挑戰的級別應試。</li>
          <li>每級各回限測驗一次，恕無法重複考試。</li>
          <li>請以電腦考試。</li>
          <li>測驗題型：模擬最新JLPT題型，考完立即呈現成績結果。</li>
          <li>測驗題數：共40小題，滿分180分。</li>
          <li>測驗時間：約 60 分鐘，畫面右上角有倒數計時，提醒作答剩餘時間。</li>
        </ul>
      </div>
      <table class="rwd-table">
        <tr>
          <th>{{$t('Personal.Exam.Type')}}</th>
          <th>{{$t('Personal.Exam.Result')}}</th>
          <th>{{$t('Personal.Exam.Exam_date')}}</th>
          <th>{{$t('Personal.Exam.Test_time')}}</th>
          <th>{{$t('Personal.Exam.Listening_score')}}</th>
          <th>{{$t('Personal.Exam.Reading_score')}}</th>
          <th>{{$t('Personal.Exam.Total_score')}}</th>
          <th class="rwd-hide">{{$t('Personal.Exam.State')}}</th>
        </tr>
        <tr v-for="(item, index) in exams" :key="index">
          <td class="rwd-td" :data-th="$t('Personal.Exam.Type')"> {{item.name}}</td>
          <td v-if="item.cefr !== null" :data-th="$t('Personal.Exam.Result')"> {{item.cefr > 0?'通過':'未通過'}}</td>
          <td v-else :data-th="$t('Personal.Exam.Result')"> - </td>
          <td :data-th="$t('Personal.Exam.Exam_date')"> {{item.finished_at?item.finished_at:'N/A'}}</td>
          <td :data-th="$t('Personal.Exam.Test_time')"> {{item.total_time}}</td>
          <td :data-th="$t('Personal.Exam.Listening_score')"> {{item.listening_score !== null ? item.listening_score : '-'}}</td>
          <td :data-th="$t('Personal.Exam.Reading_score')"> {{item.listening_score !== null ? item.reading_score : '-'}}</td>
          <td :data-th="$t('Personal.Exam.Total_score')"> {{item.listening_score !== null ? item.score : '-'}}</td>
          <td v-if="item.view_id=== ''" class="rwd-hide" :data-th="$t('Personal.Exam.Start')">
            {{moment(item.begin_at).format('YYYY-MM-DD')}} 開放
          </td>
          <td v-else class="rwd-hide" :data-th="$t('Personal.Exam.Start')">
            <button v-if="!item.finished_at" type="button" @click="goExam(item)" class="btn btn-danger">開始</button>
            <button v-else :disabled="item.finished_at" type="button" class="btn btn-danger">完成</button>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="currentPage === 'simulation'">
      <div v-for="(item, index) in exams" :key="index">
        <div class="d-flex">
          <table class="rwd-table">
            <tbody>
              <tr>
                <th style="text-align:center;" rowspan="2"> {{item.name}}</th>
                <th style="width:200px">{{$t('Personal.Exam.Exam_date')}}</th>
                <th>{{$t('Personal.Exam.Test_time')}}</th>
                <th>{{$t('Personal.Exam.Listening_score')}}</th>
                <th>{{$t('Personal.Exam.Reading_score')}}</th>
                <th>{{$t('Personal.Exam.Total_score')}}</th>
                <th>{{$t('Personal.Exam.Number_of_exercises')}}</th>
              </tr>
              <tr>
                <td class="rwd-td" style="display:none" :data-th="item.name">
                </td>
                <td :data-th="$t('Personal.Exam.Exam_date')">
                  {{item.finished_at?item.finished_at:'N/A'}}
                </td>
                <td :data-th="$t('Personal.Exam.Test_time')">
                  {{item.total_time}}
                </td>
                <td :data-th="$t('Personal.Exam.Listening_score')">
                  {{item.listening_score?item.listening_score:'-'}}
                </td>
                <td :data-th="$t('Personal.Exam.Reading_score')">
                  {{item.reading_score?item.reading_score:'-'}}
                </td>
                <td :data-th="$t('Personal.Exam.Total_score')">
                  {{item.score?item.score:'-'}}
                </td>
                <td :data-th="$t('Personal.Exam.Number_of_exercises')">
                  {{item.count}}
                </td>
              </tr>
            </tbody>
          </table>
          <button type="button" style="width:80px" class="btn btn-danger m-1" @click="goExam(item)">開始</button>
          <button :disabled="!item.finished_at" type="button" style="width:80px" class="btn btn-danger m-1" @click="goDetailAnswer(item)">詳解</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import Video from '@/components/Video.vue'
import api from '../store/api'
export default {
  name: 'ExamArea',
  data () {
    return {
      exams: [],
      currentPage: 'classify',
      monthlyExam: {},
      allowExam: false,
      time: 0,
      level: null,
      exam_date: null,
      chats: [
        {
          level: 'N1',
          text:[
            '能夠理解在廣泛的場合中所使用的日語。日語能力達到純熟，可應用於例如職場商務、大型場合，甚至升學考試、求職面試中。​',
          ],
          advice: '此階段學員已有卓越的日語能力，相信任何主題都能符合您的學習需求。建議您除了自己感到興趣的主題之外，可專攻「拜訪、接聽電話」、「撰寫電子郵件」等高級職場日語主題課程，並且實際應用在職場上，讓自己更熟悉進階日語閱讀及寫作。'
        },
        {
          level: 'N2',
          text:[
            '能夠理解日常生活中的日語，並且對於廣泛的場合中所使用的日語有一定程度的理解。雖對於正式場合或專業領域中的日語理解偶有不足，但仍能應對日常生活中各情境。​​',
          ],
          advice: '此階段學員已有相當高的日語能力，若已學習完前述課程，建議可學習職場日語相關課程，例如「公司內部的溝通、應對」，使用更進階的日語應付職場上的溝通、應對進退。'
        },
        {
          level: 'N3',
          text:[
            '對於日常生活中的日語有一定程度的理解。日語能力已邁向進階，聽說讀寫能力逐漸進步，語彙量、文法觀念也迅速累積中。​',
          ],
          advice: '此階段學員已具備閱讀進階文章的能力，因此建議可學習文化日語中的「日本經典故事選」、「日語慣用句達人就是你」、「日本文化面面觀」等課程。認識日本文化亦是日語學習中重要的一環。若能認識這些文化、歷史背景，相信您的日語會更有深度。此外，也建議學習「閱讀日語漫遊日本」、「帶日本朋友遊台灣」等長篇閱讀課程。藉由受人喜愛的旅遊主題，加深閱讀能力及語彙、文法能力。此外，也建議開始學習「掌握職場基本禮儀」、「練習職場用語入門」，學習職場實用日語，練習適切的日語表現。'
        },
        {
          level: 'N4',
          text:[
            '能夠理解基礎的日語。雖無法應對所有日常生活情境，但已有基礎的語彙量及文法概念。可將基本日語能力應用於餐廳、問路、閱讀指示等基本日常用途。​',
          ],
          advice: '此階段學員已有相當的基礎，建議開始學習「生活日語」類別中的課程。例如：「吃美食學日語」、「最新日語流行語」當中以輕鬆有趣的話題，帶領您學習課本上學不到的實用日語。「主題式情境會話」中則以真人會話影片讓您練習實用口語會話。此外，也建議學習「旅遊必備日語會話」，熟悉食衣住行常用的對話表現方式，讓您學習更得心應手。'
        },
        {
          level: 'N5',
          text:[
            '對於基礎的日語有一定程度的理解。為語彙量、文法量的萌芽階段。可閱讀簡單的指示、能以簡單的名詞句、形容詞句或動詞句進行會話。亦能聆聽理解句子中的關鍵。'
          ],
          advice: '此階段學員建議先從「平假名跟我學」、「片假名跟我學」開始學習。若已熟悉五十音，則建議開始學習「基礎日語必備課程」，認識日文裡必備的基礎句型、學習日常生活中常用的單字，以及簡單的招呼語。待學習完「基礎日語必備課程」之後，請繼續學習「初級日語必備課程」。循序漸進學習，如此便可奠定良好的日語基礎。另外建議搭配「必學日語基礎文法」，透過老師的真人解說，為您掌握文法關鍵、解惑學習文法時常見的混淆點。此外，「圖解實用日語百科」中有豐富的生活主題類別單字，能讓您迅速累積語彙量。 '
        },
        {
          level: 'C2',
          text:[
            '能聽懂、讀懂各類主題及體裁的內容，理解程度與受過高等教育之母語人士相當。​',
            '能在各種不同場合以正確流利之英語表達看法；能適切引用文化知識、慣用語詞與專有名詞。​',
            '能撰寫不同性質的文章，如企劃報告、專業/學術性摘要、論文、新聞報導及時事評論等。對於各類主題均能有效完整地闡述並作深入探討。'
          ],
          advice: '此階段學員已具備菁英級母語人士能力，相當於英語學術界專業人士的程度。建議此階段的學員持續以「新聞英語」中的「科技創新」、「商業財經」、「深度旅遊」等主題，單看影片不開「文字」功能，維持英文聽力與字彙。也可參與「直播教室」進行主題式增能課程。'
        }
      ]
    }
  },
  computed: {
    examRole () {
      if (this.$store.state.auth.user.forcePreTest) {
        if (this.$store.state.auth.user.isFinishPreTest) {
          return true
        }else {
          return false
        }
      }else {
        return true
      }
    }
  },
  watch: {
    currentPage () {
      this.level = null
      this.getExamData()
    }
  },
  mounted () {
    this.getExamData()
    this.getMonthlyExam()
    this.timer = setInterval(this.countdown, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    async getExamData () {
      const { data } = await api.get(`/contents/exams?page=1&pageSize=10&type=${this.currentPage}`)
      this.exams = data.data
      this.level = data.data[0].level
    },
    async getExamId (id) {
      const { data } = await api.get(`/contents/exams/${id}`)
      return data.data.view_id
    },
    async getMonthlyExam () {
      const self = this
      const { data } = await api.get(`/contents/monthly_exam`)
      this.monthlyExam = data.data
      const beginTime = new Date(data.data.begin_at)
      this.time = (Date.parse(beginTime) - Date.now())/1000
    },
    async getMonthlyExamRecord () {
      const { data } = await api.get(`/contents/monthly_exams`)
      this.exams = data.data
    },
    async goExam (el) {
      this.$router.push({ name: 'Examination', params: { examId: el.id, viewId: el.view_id } })
    },
    goDetailAnswer (el) {
      this.$router.push({ name: 'DetailAnswer', params: { examId:el.id, viewId: el.view_id, recordId: el.record_id } })
    },
    doSubTitle (el) {
      this.currentPage = el
      this.getExamData()
      // if (el !== 'regular') {
      //   this.getExamData()
      // } else {
      //   this.getMonthlyExamRecord()
      // }
    },
    countdown() {
      this.time --
      if (this.time <= 0 ) {
        this.allowExam = true
        clearInterval(this.timer)
      }
    },
    timeStamp (s) {
      var time = parseInt(s) + "秒"
      if( parseInt(s)> 60) {
        var second = parseInt(s) % 60
        var min = parseInt(s / 60)
        time = min + "分" + second + "秒"
        if( min > 60 ){
          min = parseInt(s / 60) % 60;
          var hour = parseInt( parseInt(s / 60) /60 );
          time = hour + "小時" + min + "分" + second + "秒";

          if( hour > 24 ){
            hour = parseInt( parseInt(s / 60) /60 ) % 24;
            var day = parseInt( parseInt( parseInt(s / 60) /60 ) / 24 );
            time = day + "天" + hour + "小時" + min + "分" + second + "秒";
          }
        }
      }
      return time
    }
  },
  components: {
    // Video
  }
}
</script>
<style lang="scss" scoped>
table {
  text-align: center;
  border-collapse: collapse;
  width: 100%;
  overflow: auto;
}
th {
  overflow:hidden;
  background: #428bca;
  border: 4px solid #fff;
  color: #fff;
  text-align: center !important;
  padding: 8px;
}
td {
  background-color: rgb(230, 230, 230);
  border: 4px solid #fff;
  text-align: center !important;
  padding: 8px;
}
.title-box {
  margin-bottom: 20px;
  border-top: #aaa solid 1px;
  border-bottom: #aaa solid 1px;
  padding: 10px 0;
  .title {
    color: #aaa;
    margin: 0 50px 0 0;
    cursor: pointer;
  }
  .active {
    color: black;
  }
}
.explanation{
  margin: 20px 0 50px 0;
  .red-text {
    font-weight: bold;
    color: red;
  }
  ul {
    list-style-type:decimal;
    padding: 0 20px;
  }
}
.rwd-table {
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
}

.rwd-table tr:first-child {
  border-top: none;
  background: #428bca;
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ebf3f9;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table td:first-child {
  margin-top: .5em;
}

.rwd-table td:last-child {
  margin-bottom: .5em;
}

.rwd-table td:before {
  content: attr(data-th) "： ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: #000;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  padding: .5em 1em;
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
  .rwd-td {
    display: block !important;
    background-color: #04a2d6;
  }
  button  {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    text-align: left  !important;
  }
  .rwd-hide {
    display: none !important;
  }
}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
  .alert-warning {
    display: none !important;
  }
}
</style>
