<template>
  <div class="container-fluid my-3 record" style="min-height:50vh">
    <div class="row">
      <div class="col-12">
        <div class="d-flex title-box">
          <div class="title" :class="{'active': currentPage==='Overview'}"  @click="goPage('Overview')">個人學習總覽</div>
          <div class="title" :class="{'active': currentPage==='Record'}" @click="goPage('Record')">個人學習紀錄</div>
        </div>
        <div class="d-flex align-items-center">
          <label class="m-0 mr-2">學員姓名:</label>
          <v-select style="width:200px" :clearable="false" v-model="studentId" :reduce="name => name.id" label="name" :options="studentList">
            <span slot="no-options">
              找不到搜尋結果
            </span>
          </v-select>
        </div>
        <div class="my-2" v-if="currentPage==='Overview'">
          <div class="d-flex align-items-center">
            <label class="m-0 mr-2">{{ $t('Personal.Record.Date_settings') }}:</label>
            <date-picker valueType="format" v-model="range" type="date" range placeholder="請選擇日期區間"></date-picker>
          </div>
        </div>
        <div v-else class="my-2">
          <label class="m-0 mr-2">{{ $t('Personal.Record.Course_categories') }}:</label>
          <select v-model="selectedChannel">
            <option :value="0">全部</option>
            <option v-for="(item, index) in channels" :key="index" :value="item.id">{{item.name}}</option>
          </select>
          <div class="d-flex align-items-center mt-2">
            <label class="m-0 mr-2">{{ $t('Personal.Record.Date_settings') }}:</label>
            <date-picker valueType="format" v-model="range" type="date" range placeholder="請選擇日期區間"></date-picker>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div v-if="currentPage=='Overview'">
        <!-- <table class="rwd-table">
          <tbody>
            <tr>
              <th>{{ $t('Overview.classify') }}</th>
              <th>{{ $t('Overview.time') }}</th>
              <th>{{ $t('Overview.course') }}</th>
              <th>{{ $t('Overview.exam') }}</th>
              <th>{{ $t('Overview.exam_score') }}</th>
              <th>{{ $t('Overview.exam_advance') }}</th>
              <th>{{ $t('Overview.exam_advance_with_percent') }}</th>
              <th>完成課後挑戰</th>
            </tr>
            <tr>
              <td data-th="學前測驗">{{studentLevel}}</td>
              <td data-th="總學習時">{{studentInfo.time.user}}</td>
              <td data-th="通過單元測驗課數">{{studentInfo.course.user}}</td>
              <td data-th="完成月考數">{{studentInfo.exam.user}}</td>
              <td data-th="月考成績">{{studentInfo.exam_score.user}}</td>
              <td data-th="任一月考>學前測驗">{{studentInfo.exam_advance.user}}</td>
              <td data-th="月考進步百分比">{{studentInfo.exam_advance_with_percent.user}}</td>
              <td data-th="完成課後挑戰">{{studentInfo.practice.user}}</td>
            </tr>
          </tbody>
        </table> -->
        <div v-for="(item, index) in summaryRecord" :key="index">
          <div class="d-flex">
            <table class="rwd-table">
              <tbody>
                <tr>
                  <th class="th-title" style="text-align:center;" rowspan="2">
                    <img :src="require('../../assets/image/icon&btn/icon_title/'+item.category_name+'.png')" alt="">
                  </th>
                  <th>{{ $t('Personal.Info.Total_study_time') }}</th>
                  <th>{{ $t('Personal.Record.Number_of_completed_courses') }}</th>
                  <th>{{ $t('Overview.practice') }}</th>
                </tr>
                <tr>
                  <td class="rwd-td" style="display:none" :data-th="item.category_name">
                  </td>
                  <td data-th="學習時數">
                    {{item.study_time ? item.study_time : 0}}
                  </td>
                  <td data-th="完成課數">
                    {{item.pass_course ? item.pass_course : 0}}
                  </td>
                  <td data-th="通過課後挑戰數">
                    {{item.complete_practice ? item.complete_practice : 0}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-for="(item, index) in recordInfo" :key="index">
          <div class="d-flex">
            <table class="rwd-table">
              <tbody>
                <tr>
                  <th class="th-title" style="text-align:center; width:350px!important;background-size:cover;" rowspan="2" :style="{ backgroundImage: 'url(' + item.banner + ')' }">
                    <div>
                      {{item.level1}} > {{item.level2}}
                    </div>
                    <h5>{{item.name}}</h5>
                  </th>
                  <th>{{ $t('Personal.Record.History') }}</th>
                  <th>{{ $t('Personal.Info.Total_study_time') }}</th>
                  <th>{{ $t('Personal.Record.Unit_test_score') }}</th>
                </tr>
                <tr>
                  <td class="rwd-td" style="display:none" :data-th="item.name">
                  </td>
                  <td data-th="最近學習時間">
                    {{moment(item.recent).format('YYYY/MM/DD HH:mm:ss') }}
                  </td>
                  <td data-th="學習時數">
                    {{item.time}}
                  </td>
                  <td data-th="單元測驗分數">
                    {{item.score? item.score: 0}}
                  </td>
                </tr>
              </tbody>
            </table>
            <button type="button" style="width:100px" class="btn btn-danger m-1" @click="goLesson(item.id)">前往課程</button>
          </div>
        </div>
        <div v-if="recordInfo.length===0" class="remind">
          <h3>尚無課程學習紀錄</h3>
        </div>
        <pagination v-if="pageCount>1" class="m-auto pt-4" :current="pageIndex" :total="pageCount" v-on:page="toPage"/>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Pagination from '../../components/Pagination.vue'
import api from '../../store/api'
import moment from 'moment';
export default {
  name: 'PersonalDetail',
  data () {
    return {
      currentPage: 'Overview',
      range: [ moment(new Date(new Date().getTime()- 6 * 24 * 3600 * 1000)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
      channels: [],
      studentList: [],
      studentInfo: {},
      studentLevel: '',
      selectedChannel: 0,
      summaryRecord: null,
      recordInfo: null,
      studentId: 0,
      pageIndex: 1,
      pageSize: 10,
      pageCount: 0,
      selected: '',
      newlist: []
    }
  },
  props:{
    groupId: {
      require: true,
      default: null
    }
  },
  components: {
    Pagination,
    DatePicker
  },
  watch: {
    selectedChannel (val) {
      this.pageIndex = 1
      this.getRecordInfo(this.studentId)
    },
    studentId (val) {
      this.pageIndex = 1
      this.getSummaryRecord(val)
      this.getRecordInfo(val)
      this.getStudentInfo(val)
      this.getCefr(val)
    },
    selected (val) {
      this.selectedList(val)
    },
    range(val) {
      this.getSummaryRecord(this.studentId)
      this.getRecordInfo(this.studentId)
    },
    groupId(val) {
      this.getGroupStudent(val)
    }
  },
  mounted () {
    this.getGroupStudent(this.groupId)
    this.getChannel()
  },
  methods: {
    goPage (el) {
      this.currentPage = el
    },
    async getGroupStudent (groupId) {
      const params = {
        pageSize: 5000
      }
      const { data } = await api.get(`/groups/${groupId}/students`, { params })
      this.studentList = data.data
      this.studentId = data.data[0].id
    },
    async getSummaryRecord (id) {
      const params = {
        beginAt: this.range[0],
        endAt:  this.range[1],
      }
      const { data } = await api.get(`/records/students/${id}/learning_records`, { params })
      this.summaryRecord = data.data
    },
    async getStudentId () {
      const { data } = await api.get('/me')
      this.studentId = data.student_id
    },
    async getRecordInfo (id) {
      const params = {
        beginAt: this.range[0],
        endAt:  this.range[1],
        page: this.pageIndex,
        pageSize: this.pageSize,
        categoryId: this.selectedChannel
      }
      const { data } = await api.get(`/records/students/${id}/learning_history`, { params })
      this.recordInfo = data.data
      this.pageCount = data.pageCount
    },
    async getLessonId (id) {
      const { data } = await api.get(`/contents/courses/${id}`)
      if (data.status === 'Success') {
        return data.data
      }
    },
    async getStudentInfo (id) {
      const { data } = await api.get(`/students/${id}/records`)
      this.studentInfo = data.data
    },
    async getCefr (id) {
      const { data } = await api.get(`/students/${id}`)
      this.studentLevel = data.data.initial_level
    },
    async goLesson (id) {
      await this.getLessonId(id).then((val) => {
        this.$router.push({ name: 'CourseLesson', params: { lesson: val.id, courseId: val.sections[0].data.view_id||val.sections[0].id } })
      } )
    },
    async getChannel () {
      const { data } = await api.get('/contents/channels')
      this.channels = data.data
    },
    toPage (toPage) {
      this.pageIndex = toPage
      this.getRecordInfo(this.studentId)
    },
    selectedList (search) {
      const that = this
      this.getGroupStudent(this.groupId)
      const array = []
      this.studentList.map((el)=> {
        if (el.name.indexOf(search) >= 0) {
          array.push (el)
        }
      })
      this.newlist = array
    },
    selectedStudent(val) {
      this.selected = val.name
      this.studentId = val.id
      this.newlist = []
    },
    async postReports () {
      try {
        await api.get(`/reports/students/${this.studentId}/history`)
        this.$swal({
          title: '報表計算完成後，將自動發送至您的信箱',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
      } catch (error) {
        if (error) {
          this.$swal({
            title: '目前報表功能異常，請聯絡管理者',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: '確定'
          })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/color.scss";
.record {
  text-align: left;
}
table {
  border-collapse: collapse;
  width: 100%;
  overflow: auto;
}
th {
  overflow:hidden;
  // background: #428bca;
  border: 4px solid #fff;
  color: #fff;
  text-align: center !important;
  padding: 8px;
}
td {
  background-color: rgb(230, 230, 230);
  border: 4px solid #fff;
  text-align: center !important;
  padding: 8px;
}
.th-title {
  width: 220px;
  height: 120px;
  padding: 0 !important;
  position: relative;
  img {
    top:0;
    left: -5px;
    position: absolute;
    width: 105%;
    height: 100%;
  }
}
.remind {
  padding: 10% 0;
  text-align: center;
}
.title-box {
  margin-bottom: 20px;
  border-top: #aaa solid 1px;
  border-bottom: #aaa solid 1px;
  padding: 10px 0;
  .title {
    color: #aaa;
    margin: 0 50px 0 0;
    cursor: pointer;
  }
  .active {
    color: black;
  }
}
.download-btn {
  text-align: right;
}
.select-box {
  padding: 2px;
  border: solid 1px #ddd;
  width: 100%;
  overflow: auto;
  background-color: #fff;
  position: absolute;
  z-index: 50;
  max-height: 300px;
}
.rwd-table {
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
}

.rwd-table tr:first-child {
  border-top: none;
  background: $table-red;
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ebf3f9;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table td:first-child {
  margin-top: .5em;
}

.rwd-table td:last-child {
  margin-bottom: .5em;
}

.rwd-table td:before {
  content: attr(data-th) "： ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: #000;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  padding: .5em 1em;
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
  .rwd-td {
    display: block !important;
    background-color: #04a2d6;
  }
  button  {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    // padding: 1em !important;
  }
}
</style>
