<template>
  <div>
    <div class="banner">
      <div class="banner-box">
        <div>
          <h2>
            電子學習報
          </h2>
          <p>
            News Letter
          </p>
          <p>
            (本學習報僅供便利連結，不列入學習紀錄)
          </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row my-5">
        <div class="col-12 d-flex justify-content-center">
          <div class="mr-2">
            <img class="title-btn" v-if="type=='live'" src="../assets/image/icon&btn/icon_btn/btn_Live.png" alt="">
            <img class="title-btn pointer" v-else src="../assets/image/icon&btn/icon_btn/btn_Live_G.png" @click="type='live'"  alt="">
          </div>
          <div class="ml-2">
            <img class="title-btn" v-if="type==='cnn'" src="../assets/image/icon&btn/icon_btn/btn_CNN.png" alt="">
            <img class="title-btn pointer" v-else src="../assets/image/icon&btn/icon_btn/btn_CNN_R.png" @click="type='cnn'" alt="">
          </div>
        </div>
        <div class="col-md-12">
          <hr>
          <div class="row mt-2">
            <div class="col-lg-3 col-md-6 col-sm-12 my-2" v-for="(item, index) in letterList" :key="index">
              <button class="btn lesson-btn" type="button" @click="goPage(item.Url)">
                <div class="my-2">
                  <div>
                    {{ item.Year + '/' + item.Month + '/' + item.Day}}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
export default {
  name: 'NewsLetter',
  watch: {
    type (val) {
      this.getLetterList()
    }
  },
  data () {
    return {
      letterList: [],
      type: 'live',
      selected: ''
    }
  },
  mounted () {
    this.scrollTop()
    this.getLetterList()
  },
  methods: {
    async getLetterList () {
      const { data } = await axios.post(`https://api.liveabc.com/v1/epapers?magName=${this.type}&from=2021-12-01`)
      this.letterList = data
      this.selected = data[0].Url
      console.log(data)
    },
    goPage (el) {
      window.open(el)
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../assets/styles/color.scss";
  @import "../assets/styles/custom.scss";
  .container {
    text-align: left;
  }
  .banner {
    height: 250px;
    background-image: url('../assets/image/home/banner_bg_newsletter_top.jpg');
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-position: center;
    background-size: cover;
    .banner-box {
      padding: 60px 0 10px 0;
      h2 {
        font-size: 3rem;
        font-weight: 800;
        margin-bottom: 1rem;
      }
      p {
        font-weight: 600;
        margin: 0;
        font-size: 1.25rem;
      }
    }
  }
  .btn:focus,
  .btn:active:focus,
  .btn.active:focus,
  .btn.focus,
  .btn:active.focus,
  .btn.active.focus {
    outline: none;
    box-shadow: none !important;
  }
  .lesson-btn {
    width: 100%;
    height: 100%;
    margin: 5px 0 0 0;
    border-radius: 5px;
    border: solid rgb(143, 143, 143) 1px;
  }
  .lesson-btn:hover {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  }
  .title-btn {
    width: 100%;
    max-width: 200px;
  }
</style>
