<template>
  <div class="container detail-answer" style="margin:130px auto">
    <div class="row" v-for="(item, index) in detailAnswer.Parts" :key="index">
      <div class="col-12">
      </div>
      <div class="col-12">
        <div class="p-3">
          <h4 v-html="forceToJap(item.Name)"></h4>
          <h5 v-html="forceToJap(item.Description)"></h5>
          <div v-for="(el, idx) in item.Questions" :key="idx">
            <div class="py-3">
              <h5 v-if="el.Childs">Question {{el.Num + '-' + (el.Num +el.Childs.length-1)}}</h5>
              <h5 v-else>{{el.Num}}.
                <span class="wrong" v-if="userAnswer.map((e)=>{return e.no}).indexOf(el.Num)>=0" :class="{'answer': userAnswer[userAnswer.map((e)=>{return e.no}).indexOf(el.Num)].user_answer.toUpperCase() === el.Answer}">
                  {{userAnswer[userAnswer.map((e)=>{return e.no}).indexOf(el.Num)].user_answer.toUpperCase()}}
                </span>
                <span class="wrong" v-else>
                  未作答
                </span>
              </h5>
              <h5 class="ml-3" v-if="el.Title" style="line-height:2.5rem" v-html="forceToJap(el.Title)"></h5>
            </div>
            <div style="width:400px">
              <img v-if="el.ImageUrl" style="width:100%" :src="el.ImageUrl" alt="">
            </div>
            <div v-if="item.IsPackage && el.explain" class="explain-box">
              <span v-html="forceToJap(el.explain)">
              </span>
            </div>
            <audio v-if="el.AudioUrl" controls class="my-3">
              <source :src="el.AudioUrl" type="audio/mp3">
            </audio>
            <br>
            <div v-if="!item.IsPackage">
              <div>
                <ul v-for="(e, i) in el.Items" :key="i">
                  <li :class="{'answer': e.Key===el.Answer}">{{e.Key}}. <span v-html="forceToJap(e.Text)"></span> </li>
                </ul>
              </div>
              <div v-if="el.explain" class="explain-box">
                <span v-html="forceToJap(el.explain)">
                </span>
              </div>
            </div>
            <div v-else>
              <div v-for="(e, i) in el.Childs" :key="i">
                <h5 class="mt-2">{{e.Num}}.
                  <span class="wrong" v-if="userAnswer.map((x)=>{return x.no}).indexOf(e.Num)>=0" :class="{'answer': userAnswer[userAnswer.map((x)=>{return x.no}).indexOf(e.Num)].user_answer.toUpperCase() === e.Answer}">
                    {{userAnswer[userAnswer.map((x)=>{return x.no}).indexOf(e.Num)].user_answer.toUpperCase()}}
                  </span>
                  <span class="wrong" v-else>
                    未作答
                  </span>

                </h5>
                <h5 v-if="e.Title" class="ml-3" v-html="forceToJap(e.Title)"></h5>
                  <ul v-for="(t, i) in e.Items" :key="i">
                    <li :class="{'answer': t.Key===e.Answer}">{{t.Key}}. <span v-html="forceToJap(t.Text)">
                    </span></li>
                  </ul>
                  <div v-if="e.explain" class="explain-box">
                    <span v-html="forceToJap(e.explain)">
                    </span>
                  </div>
              </div>
            </div>
            <hr>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import Video from '@/components/Video.vue'
import api from '../store/library/api'
import Proapi from '../store/api'
export default {
  name: 'DetailAnswer',
  data () {
    return {
      detailAnswer: {},
      userAnswer: []
    }
  },
  mounted () {
    this.getDetailAnswer()
    this.getUserAnswer()
    this.scrollTop ()
  },
  methods: {
    async getDetailAnswer () {
      const { data } = await api.get(`/v1/livelibrary/Exam/${this.$route.params.viewId}/guide`)
      if (data.status === 'OK') {
        this.detailAnswer = data.data
      }
    },
    async getUserAnswer () {
      const { data } = await Proapi.get(`/exams/${this.$route.params.examId}/records/${this.$route.params.recordId}/answers`)
      this.userAnswer = data.data
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    },
    forceToJap (sent, hidePinyin = false) {
      const split = sent.split(/{(.+?)}/)
      let newSent = ''
      for (const i in split) {
        if (split[i].indexOf('=') > -1) {
          if (!hidePinyin) {
            newSent += '<ruby>' + split[i].split(/=/)[0] + '<rt>' + split[i].split(/=/)[1] + '</rt></ruby>'
          } else {
            newSent += split[i].split(/=/)[0]
          }
        } else {
          newSent += split[i]
        }
      }
      return newSent
    }
  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
  .detail-answer {
    padding: 15px 20px;
    white-space: pre-line;
    background-color: #f7f7f7;
    text-align: left;
    ul {
      // padding-left: 10px;
      li {
        list-style-type: none;
      }
    }
    .explain-box {
      padding: 10px;
      border: solid 1px rgb(4, 1, 187);
    }
  }
  .answer {
    color: rgb(0, 148, 0) !important;
  }
  .wrong {
    color: red;
  }
</style>
