<template>
  <div>
    <router-view :lifeData="lifeData" :bizData="bizData"></router-view>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from '@/components/Pagination.vue'
import api from '../../store/api'
export default {
  name: 'WritingLayout',
  components: {
    Pagination
  },
  computed: {

  },
  data () {
    return {
      lifeData: [],
      bizData: [],
      writing: []
    }
  },
  mounted () {
    this.scrollTop()
    this.getWritingData()
  },
  methods: {
    async getWritingData () {
      const params = {
        page: 1,
        pageSize: 26
      }
      const { data } = await api.get(`/writings`, { params })
      data.data.map((el => {
        if (el.category === '生活實用') {
          this.lifeData.push(el)
        } else if (el.category === '職場商用') {
          this.bizData.push(el)
        }
      }))
      this.lifeData.sort((a, b) => a.sort.localeCompare(b.sort))
      this.bizData.sort((a, b) => a.sort.localeCompare(b.sort))
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
