<template>
  <footer class="footer">
    <div class="container" :class="{'footer-rwd': this.$route.name === 'CourseLesson'}">
      <div class="row py-5">
        <div class="row justify-content-around col-md-8">
          <div class="col-sm-9 col-9">
            <h4>關於我們</h4>
            <p>
              MI Learning品牌由Magazines International (Asia) Limited (馬己仙國際(亞洲)有限公司)創立。我們搜羅全球具教學權威的互動語文課程，以創新的多媒體線上課程、專業外籍老師，提供全方位聽說讀寫的學習環境，匯聚語文教學法的精髓(學習、練習、應用)，多年來致力為國際知名的社企、私人學員提供外語培訓，實現更有效的自我增值。
            </p>
          </div>
          <div class="col-sm-3 col-3">
            <h4>
              相關連結
            </h4>
            <p>
              <a target="_blank" href="https://www.facebook.com/milearninghk/">Facebook</a>
            </p>
            <p>
              <a target="_blank" href="http://www.mi-learning.com/">官方網站</a>
            </p>
          </div>
        </div>
        <div class="footer-title col-md-4 m-auto text-center">
          <h2>MI Learning</h2>
          <div style="height:180px;">
            <img style="width:150px" src="../assets/QR-code.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// import store from '../store';
export default {
  name: 'Footer',
  data () {
    return {
      locale: window.localStorage.getItem('locale') || 'tw'
    }
  },
  mounted () {
  },
  methods: {
    setLocale (locale) {
      window.localStorage.setItem('locale', locale || 'tw')
      this.locale = locale
      this.$store.commit("setLocal")
      this.scrollTop()
      return window.history.go(0)
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/color.scss";
@import "../assets/styles/custom.scss";
  .footer {
    background-color: $main-color;
    min-height: 150px;
    color: $white-color;
    position: relative;
    .footer{
      text-align: left;
    }
    h4 {
      text-align: left;
      font-weight: bolder;
    }
    p {
      text-align: left;
    }
    .footer-title {
      h3 {
        @include text(5rem, .2rem, white);
      }
    }
  }
  a,a:hover {
    color: #fff;
  }
  @media screen and (max-width: 600px) {
    .footer-rwd {
      display: none;
    }
  }
</style>
