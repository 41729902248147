<template>
  <div class="announcement">
    <transition name="fade">
      <div class="pop-body" v-if="toggle">
        <div class="main-title p-3 d-flex justify-content-between">
          <h3 class="p-2" style="color:#fff;font-weight:bold">站長報報</h3>
          <span @click="allRead()" class="read-btn p-2 my-auto pointer">
            全部已讀
          </span>
        </div>
        <div class="content-layout" v-if="!announcementData">
          <div class="content-scroll" v-for="(item, index) in announcement" :key = index>
            <div class="content-box">
              <font-awesome-icon v-if="item.read_at===null" size="lg" :icon="['fa', 'envelope']"  style="color:rgba(255, 0, 0, .6);float: right;"/>
              <font-awesome-icon v-else size="lg" :icon="['fa', 'envelope-open-text']"  style="color:rgba(255, 0, 0, .6);float: right;"/>
              <h4 style="font-weight:bold" class="p-2 mb-0">{{item.title}}</h4>
              <div class="mb-1 mx-2">
                <span v-if="item.type=== 'all'" class="badge badge-success">全站</span>
                <span v-else class="badge badge-danger">企業</span>
              </div>
              <div class="px-2">
                <p>{{item.content}}</p>
              </div>
              <div style="text-align:center">
                <button type="button" @click="openAnnouncement(item)" class="btn btn-dark">了解更多</button>
              </div>
            </div>
          </div>
        </div>
        <div class="content-layout" v-else>
          <div class="content-scroll">
            <div class="content-box d-flex flex-column" style="min-height:300px">
              <h4 class="p-2 mb-0">{{announcementData.title}}</h4>
              <hr style="border: 1px solid red; width:90%">
              <div class="content px-2" v-html="announcementData.content">
              </div>
              <div style="text-align:center">
                <button type="button" @click="back()" class="btn btn-dark">返回</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="bubble pointer" @click="toggle = !toggle">
      <div v-if="unread_count" class="circle">
        {{unread_count}}
      </div>
      <img class="p-3" v-if="!toggle" style="width:100%" src="../assets/image/pic/btn_news_in_on.png" alt="">
      <img class="p-3" v-else style="width:100%" src="../assets/image/pic/btn_news_in_off.png" alt="">
    </div>
  </div>
</template>

<script>
import api from '../store/api'
export default {
  name: 'Announcement',
  data () {
    return {
      toggle: false,
      announcement: null,
      announcementData: null,
      unread_count: 0
    }
  },
  mounted () {
    this.getAnnouncement()
    this.getUnreadCount()
  },
  methods: {
    async getAnnouncement () {
      const options = { headers: {'showLoading': false }}
      const { data } = await api.get('news', options)
      this.announcement = data.data
    },
    async getUnreadCount () {
      const options = { headers: {'showLoading': false }}
      const { data } = await api.get('news/unread_count', options)
      this.unread_count = data.data.count
    },
    async openAnnouncement (el) {
      const options = { headers: {'showLoading': false }}
      const { data } = await api.get(`news/${el.id}`, options)
      this.announcementData = data.data
      this.getUnreadCount()
    },
    async allRead () {
      await api.post(`news`)
      this.getAnnouncement()
      this.getUnreadCount()
    },
    back () {
      this.announcementData = null
      this.getAnnouncement()
      this.getUnreadCount()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/color.scss";
  .announcement {
    z-index:5050;
    position: fixed;
    bottom: 0;
    right: 0;
    .pop-body {
      width:380px;
      height:500px;
      background:#fff;
      border: solid 1px #ccc;
      text-align:left;
      position:relative;
      border-radius: 10px;
      box-shadow: 0px 0px 5px  rgb(109, 109, 109);
      .main-title {
        height:100px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background:red;
      }
      .content-layout {
        height: 420px;
        overflow: auto;
        position: absolute;
        top:10%;
        padding: 10px;
        margin: 20px 0 0 0;
        .content-scroll {
          padding: 0px 30px;
          .content-box {
            box-shadow: 0px 0px 10px  rgb(109, 109, 109);
            border: solid #ccc 1px;
            border-radius: 5px;
            width: 350px;
            overflow: auto;
            background: #fff;
            margin: 0px 0px 20px -15px;
            padding: 10px;
            .content {
              min-height: 200px
            }
          }
        }
      }
    }
    .bubble {
      margin: 10px;
      float: right;
      width: 60px;
      height: 60px;
      background: #E83015;
      border-radius: 50%;
      box-shadow: 2px 2px 5px  rgb(83, 83, 83);
    }
  }
  .circle {
    right: 5px;
    font-size: .8rem;
    position: absolute;
    border-radius: 50%;
    border: .5px solid #ccc;
    width: 20px;
    height: 20px;
    color: red;
    background: #fff;
  }
  .read-btn {
    color: #fff;
    text-decoration: underline;
  }
  .fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
